<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                  ###### -->
<!-- ###### @date: Marzo 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <!-- Filtros para buscar aseguradoras -->
        <section class="d-flex">
            <!-- Filtrar por NIT -->
            <v-text-field class="me-2 filtro" v-model="buscarNit" label="NIT" outlined dense hide-details
                @keypress="validarNumeros" @input="filtroFormato" maxlength="13">
            </v-text-field>
            <!-- Filtrar por nombre -->
            <v-text-field class="me-2 filtro" v-model="buscarNombre" label="Nombre" outlined dense hide-details
                @keypress="validarLetras" @input="listaAseguradora()">
            </v-text-field>
            <!-- Filtrar por codigo detalle cliente-->
            <v-text-field class="me-2 filtro" v-model="buscarCodigo" label="Detalle cliente" outlined dense
                hide-details @keypress="validarNumeros" @input="listaAseguradora()">
            </v-text-field>
            <!-- Filtrar por estado-->
            <v-select class="me-2 filtro" v-model="filtroEstado" label="Estado" outlined dense hide-details :items="estados"
                :menu-props="{ offsetY: true }" @input="listaAseguradora()">
            </v-select>
            <!-- Este botón abre un diálogo para agregar una nueva aseguradora -->
            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="dialogoAseguradora = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <!-- Diálogo para crear/editar una aseguradora -->
        <v-dialog v-model="dialogoAseguradora" transition="dialog-bottom-transition" max-width="28rem" :editar="false" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6"> {{ tituloDialogo }} </span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <!-- Formulario para ingresar los datos de la aseguradora -->
                        <v-form ref="crearAseguradora" lazy-validation>
                            <!-- Campo para ingresar el NIT de la aseguradora -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" ref="nitValidationProvider"
                                    :rules="{ required: true, nit: 13 }">
                                    <v-text-field v-model="crearAseguradora.nit" label="NIT" maxlength="13" required dense
                                        outlined :error-messages="errors" @keypress="validarNumeros" @input="formatoNIT"
                                        :disabled="disabled">
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre el campo NIT -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Número de identificación tributaria.
                                            <br>Está compuesto por nueve dígitos y un dígito de verificación.
                                            <br><em>Ejemplo: 890.985.122-6</em>
                                        </span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar el Nombre de la aseguradora -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" ref="nombreValidation"
                                    :rules="{ required: true }">
                                    <v-text-field v-model="crearAseguradora.nombreAseguradora" label="Nombre" required dense outlined
                                        :error-messages="errors" @keypress="validarLetras" @paste.prevent>
                                    </v-text-field>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre el campo Nombre -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Nombre de la aseguradora.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar el Código detalle cliente -->
                            <div class="d-flex">
                                <validation-provider class="campo select" v-slot="{ errors }" ref="codigoValidation" :rules="{ required: true }">
                                    <v-autocomplete v-model="crearAseguradora.detalleCliente" label="Detalle cliente"
                                        :error-messages="errors" :items="listaCodigos" @input="validarAseguradora()"
                                        return="codigoDetalleCliente" no-data-text="No se encontraron resultados."
                                        :menu-props="{ maxHeight: 200, offsetY: true, maxWidth: 374 }" dense outlined :disabled="disabled">
                                    </v-autocomplete>
                                </validation-provider>
                                <!-- Tooltip para mostrar información sobre el campo Código detalle cliente -->
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon fab v-bind="attrs" v-on="on" dark color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>El detalle cliente es un número único <br> que identifica cada sucursal del cliente.</span>
                                    </v-tooltip>
                                </div>
                            </div>

                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <!-- Botón para guardar cambios, si la variable editar es falsa llama a la método agregarAseguradora, 
                                    de lo contrario llama al método editarAseguradora  -->
                                <v-btn color="success" :disabled="invalid || botonDisabled" text depressed
                                    @click="!editar ? agregarAseguradora() : editarAseguradora()">
                                    Guardar
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla aseguradora -->
        <v-data-table class="elevation mt-4" :items="tablaAseguradora.items" :loading="tablaAseguradora.loading"
            fixed-header :headers="tablaAseguradora.headers" :footer-props="tablaAseguradora.footerProps"
            :items-per-page.sync="tablaAseguradora.itemsPerPage" :page.sync="tablaAseguradora.page"
            :server-items-length="tablaAseguradora.totalPage" height="64vh" :sort-by.sync="tablaAseguradora.sortField"> 
            <template v-slot:header.nombreAseguradora="{ header }">
                <span @click="ordenarColumna(header.value)">{{ header.text }} 
                    <v-icon size="large" :style="{ color: arrowColor }">{{ arrowIcono }}</v-icon>
                </span>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idAseguradora">
                        <td> {{ formatearNit(item.nit + item.digitoVerificacion) }}</td>
                        <td> {{ item.nombreAseguradora }} </td>
                        <td class="text-center">{{ item.detalleCliente }}</td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-icon medium :color="item.eliminado === false ? 'orange' : 'grey'" @click="item.eliminado === false && abrirDialogoEditar(item)"> border_color </v-icon>
                            <v-btn icon @click="abrirDialogoEstado(item)">
                                <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                    {{ item.estado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                </v-icon>
                            </v-btn>
                        </td>
                        <td class="text-center">
                            <v-icon medium color="blue" @click="verTablaTrazabilidad(item)">backup_table</v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6">
                        {{ mensajeEstado }}
                    </span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <div>
                        <p v-if="cargando" class="text-center ma-0">
                            Por favor espere un momento...
                            <v-icon :class="{ 'rotate-animation': cargando }"
                                large>rotate_right
                            </v-icon>
                        </p>
                    </div>
                    <div class="d-flex justify-end">
                        <v-btn v-if="!cargando" class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn v-if="!cargando" color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <div v-if="errorEstado" class="overlay">
            <v-alert class="alerta" persistent transition="dialog-bottom-transition">
                <span class="d-flex justify-center">NIT sin dígito de verificación. Edite el registro.</span>
            </v-alert>
        </div>

        <!-- dialogo para ver trazabilidad de una aseguradora -->
        <v-dialog v-model="dialogoTrazabilidad" persistent transition="dialog-bottom-transition" max-width="60rem">
            <v-card>
                <v-card-title class="encabezado">
                    <span class="text-h6"> Trazabilidad:
                        {{ aseguradoraSeleccionada.nombreAseguradora }}
                    </span>
                </v-card-title>
                <v-card-text class="dialogo">
                    <v-data-table class="elevation traza" :items="tablaTrazabilidad.items"
                        :headers="tablaTrazabilidad.headers" :footer-props="tablaTrazabilidad.footerProps"
                        :items-per-page="tablaTrazabilidad.itemsPerPage" :page.sync="tablaTrazabilidad.page"
                        :sort-by="'time'" :sort-desc="true" height="45vh" fixed-header>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="item in items" v-bind:key="item.rev">
                                    <td> {{ nombresCampos[item.campo] }} </td>
                                    <td>
                                        <template v-if="item.campo === 'eliminado'">
                                            <span v-if="item.anterior === false">Activo</span>
                                            <span v-if="item.anterior === true">Inactivo</span>
                                        </template>
                                        <template v-if="validarCampos(item)">
                                            {{ item.anterior }}
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="item.campo === 'eliminado'">
                                            <span v-if="item.nuevo === false">Activo</span>
                                            <span v-if="item.nuevo === true">Inactivo</span>
                                        </template>
                                        <template v-if="validarCampos(item)">
                                            {{ item.nuevo }}
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        {{ formatoFecha(item.time) }}
                                    </td>
                                    <td class="text-center">
                                        {{ item.username }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-slot:footer.page-text="items">
                            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                        </template>
                    </v-data-table>
                    <div class="d-flex justify-end mt-2">
                        <v-btn color="error" text @click="dialogoTrazabilidad = false">Cerrar</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

// validaciones con vee-validate
setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});
extend('nit', {
    validate(value, { length }) {
        return value.length === length;
    },
    params: ['length'],
    message: 'Este campo debe tener 10 caracteres.'
});
// validaciones con vee-validate---fin

export default {
    name: "Aseguradora",
    props: {
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            buscarNit: '',
            buscarNombre: '',
            buscarCodigo: '',
            filtroEstado: '',
            estados: [
                { text: 'Todos', value: '' },
                { text: 'Activo', value: false },
                { text: 'Inactivo', value: true },
            ],
            dialogoAseguradora: false,
            tituloDialogo: 'Crear aseguradora',
            crearAseguradora: {
                nit: '',
                nombreAseguradora: '',
                detalleCliente: '',
            },
            listaCodigos: [],
            lastmodifiedby: '',
            arrowColor: '',
            arrowIcono: 'arrow_upward',
            tablaAseguradora: {
                items: [],
                loading: false,
                headers: [
                    { text: "NIT", align: "left", width: "16%", sortable: false },
                    { text: "NOMBRE", value: 'nombreAseguradora', align: "left", width: "26%", sortable: false },
                    { text: "DETALLE CLIENTE", align: "center", width: "10%", sortable: false },
                    { text: "ESTADO", align: "left", width: "14%", sortable: false },
                    { text: "ACCIONES", align: "center", width: "10%", sortable: false },
                    { text: "TRAZABILIDAD", align: "center", width: "10%", sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': [10, 15, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0,
                sortDirection: 'desc',
                sortField: 'idAseguradora',
            },
            editar: false,
            dialogoCambiarEstado: false,
            aseguradoraSeleccionada: {},
            mensajeEstado: '',
            dialogoTrazabilidad: false,
            tablaTrazabilidad: {
                items: [],
                headers: [
                    { text: "CAMPO", align: "left", width: "20%", sortable: false },
                    { text: "VALOR ANTERIOR", align: "left", width: "20%", sortable: false },
                    { text: "VALOR ACTUAL", align: "left", width: "20%", sortable: false },
                    { text: "FECHA DE MODIFICACIÓN", align: "center", width: "25%", sortable: false },
                    { text: "USUARIO", align: "center", width: "15%", sortable: false },
                ],
                footerProps: {
                    'show-current-page': true,
                    'show-first-last-page': true,
                },
                itemsPerPage: 5,
                page: 1,
            },
            nombresCampos: {
                nombre_aseguradora: 'Nombre',
                detalle_cliente: 'Detalle cliente',
                eliminado: 'Estado'
            },
            validarNit: false,
            userRoles: {},
            roles: Role,
            disabled: false,
            botonDisabled: false,
            cargando: false,
            errorEstado: false,
        }
    },
    created() {
        this.userRoles = this.auth.roles;
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },
    watch: {
        'tablaAseguradora.sortField': function () {
            this.tablaAseguradora.page = 1;
            this.listaAseguradora();
        },
        'tablaTrazabilidad.items': function () {
            this.tablaTrazabilidad.page = 1;
        },
        'tablaAseguradora.page': function () {
            this.listaAseguradora();
        },
        'tablaAseguradora.itemsPerPage': function () {
            this.tablaAseguradora.page = 1;
            this.listaAseguradora();
        },
        'buscarNombre': function () {
            this.tablaAseguradora.page = 1;
            this.listaAseguradora();
        },
        'buscarNit': function () {
            this.tablaAseguradora.page = 1;
            this.listaAseguradora();
        },
        'buscarCodigo': function () {
            this.tablaAseguradora.page = 1;
            this.listaAseguradora();
        },
        'filtroEstado': function () {
            this.tablaAseguradora.page = 1;
            this.listaAseguradora();
        },
        'dialogoCambiarEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.cargando = false;
                }, 500)
            }
        }
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        ordenarColumna(headerValue) {
            const sfs = localStorage.getItem("sortfieldState");
            if (this.tablaAseguradora.sortField === headerValue) {
                if (sfs === 'asc') {
                    this.arrowIcono = 'arrow_downward'
                    this.arrowColor = 'black'
                    this.tablaAseguradora.page = 1;
                    localStorage.setItem("sortfieldState", 'desc');
                } else {
                    this.arrowIcono = 'arrow_upward'
                    this.arrowColor = 'black'
                    localStorage.setItem("sortfieldState", 'asc');
                }
            } else {
                this.arrowIcono = 'arrow_upward'
                this.arrowColor = 'black'
                localStorage.setItem("sortfieldState", 'asc');
            }
            this.tablaAseguradora.sortField = headerValue;
            this.listaAseguradora();
            if(sfs === 'desc' && headerValue === headerValue) {
                localStorage.clear();
                this.tablaAseguradora.sortField = 'idAseguradora';
                this.tablaAseguradora.sortDirection = 'desc';
                this.listaAseguradora();
                this.arrowColor = '';
                this.arrowIcono = 'arrow_upward';
            }
        },
        /**
         * Lista las aseguradoras en una tabla mediante una petición get con los siguientes parámetros: page: Página actual, 
         * size: Número de elementos por página, nombre: Nombre de la aseguradora a buscar(convertido a minúsculas) y 
         * NIT: NIT de la aseguradora a buscar,Dígito de verificación hace parte del campo de entrada del NIT, Nombre: nombre de la aseguradora a buscar, 
         * Código detalle cliente: código detalle cliente a buscar, Estado: estado de la aseguradora a buscar
         */
        listaAseguradora() {
            this.tablaAseguradora.loading = true;
            this.$http
                .get(`msa-administration/aseguradora/listarAseguradoras`, {
                    params: {
                        page: this.tablaAseguradora.page - 1,
                        size: this.tablaAseguradora.itemsPerPage,
                        nombre: `${this.buscarNombre.toLowerCase()}`,
                        nit: `${this.buscarNit.replace(/\D/g, '').substring(0, 9)}`,
                        codigoDetalleCliente: `${this.buscarCodigo}`,
                        estado: `${this.filtroEstado}`,
                        digitoVerificacion: `${this.buscarNit.replace(/\D/g, '').substring(9, 10)}`,
                        codigoEmpresa: this.enterprise.code,
                        sortField: this.tablaAseguradora.sortField,
                        sortDirection: localStorage.getItem("sortfieldState")
                    }
                }).then(response => {
                    this.tablaAseguradora.items = response.data.content;
                    this.tablaAseguradora.totalPage = response.data.totalElements;
                    this.tablaAseguradora.loading = false;
                }).catch((error) => {
                    console.log(error);
                    this.tablaAseguradora.loading = false;
                })
        },
        /**
         * Esta función se utiliza para validar que solo se ingresen números, puntos y guiones en un campo de entrada de texto
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * @param {event} event - El evento del teclado que se produce al escribir en el campo de entrada
         */
        validarNumeros(event) {
            const key = event.key;
            if (!((key >= '0' && key <= '9'))) {
                event.preventDefault();
            }
        },
        /**
         * Esta función se utiliza para validar que solo se ingresen letras, espacios en blanco, puntos y guiones en un campo de entrada de texto
         * si la tecla presionada no corresponde a la condición, se evita que se ingrese el caracter
         * @param {evento} event - El evento del teclado que se produce al escribir en el campo de entrada
         */
        validarLetras(event) {
            const key = event.key;
            if (!key.match(/[a-zA-ZáéíóúñÁÉÍÓÚÑ .-]/)) {
                event.preventDefault();
            }
        },
        /**
         * Formatea el nit,es decir, le agrega sus respectivos puntos y el guión, primero remueve cualquier caracter que no sea numerico
         * si solo se tienen 9 digitos o menos se le agregan los puntos de grupos de a 3 digitos(XXX.XXX.XXX),
         * y si tiene mas de 9 digitos se le agrega el guión despues del digito en la posición 9(XXX.XXX.XXX-Y)
         * @param {*} nit El nit a formatear
         */
        formatearNit(nit) {
            let nitTabla = nit.replace(/\D/g, '');
            if (nitTabla.length <= 9) {
                let formato = nitTabla.replace(/^(\d{3})(\d{3})(\d{1,3})?$/, "$1.$2.$3");
                nit = formato || "";
            } else {
                let formatoPuntos = nit.slice(0, 9).replace(/^(\d{3})(\d{3})(\d{1,3})?$/, "$1.$2.$3");
                let formatoGuion = nit.slice(9);
                nit = formatoPuntos + "-" + formatoGuion;
            }
            return nit;
        },
        /** Formatea el nit que se ingresa en el filtro de búsqueda */
        filtroFormato() {
            let nitFiltro = this.buscarNit.replace(/\D/g, '');
            this.buscarNit = this.formatearNit(nitFiltro);
            this.listaAseguradora();
        },
        /**
         * Busca si existe una seguradora con el mismo nit y el código detalle cliente que se está digitando en los campos de entrada, 
         * si existe no deja crear la aseguradora, si no, se habilita el botón de guardar.
         */
        validarAseguradora() {
            const nit = this.crearAseguradora.nit.replace(/\D/g, '');
            const nueveDigitos = nit.substring(0, 9);
            this.$http
                .get("msa-administration/aseguradora/validarExistencia", {
                    params: {
                        nit: nueveDigitos,
                        detalleCliente: this.crearAseguradora.detalleCliente,
                        idEmpresa : this.enterprise.code
                    }
                })
                .then((response) => {
                    if (response.data !== null) {
                        this.$refs.codigoValidation.errors = ["La aseguradora ya existe."];
                        this.botonDisabled = true;
                    } else {
                        this.botonDisabled = false;
                        this.$refs.codigoValidation.errors = [];
                    }
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Agrega una nueva aseguradora, los datos se obtienen de la variable "aseguradora" que contiene NIT, dígito de verificación, nombre, código detalle cliente
         * y código empresa, mediante una petición post se agrega el registro a la base de datos, finalmente se actualiza la tabla y se cierra el diálogo
         * el replace es para guardar el nit en base de datos sin el formato, es decir, sin el guion y los puntos.
         * Como el nit y el digito se ingresa en el mismo campo de entrada se le agrega el método substring para separarlos.
         */
        agregarAseguradora() {
            this.botonDisabled = true;
            const nit = this.crearAseguradora.nit.replace(/\D/g, '');
            const nueveDigitos = nit.substring(0, 9);
            const digitoVerificacion = nit.substring(9, 10);
            const aseguradora = {
                nit: nueveDigitos,
                digitoVerificacion: digitoVerificacion,
                nombreAseguradora: this.crearAseguradora.nombreAseguradora,
                detalleCliente: this.crearAseguradora.detalleCliente,
                eliminado: false,
                empresa: {
                    idEmpresa: this.enterprise.code
                },
                lastmodifiedby: this.lastmodifiedby,
                createdby: this.lastmodifiedby
            };
            this.$http
                .post("msa-administration/aseguradora/guardar", aseguradora)
                .then(() => {
                    this.listaAseguradora();
                    this.dialogoAseguradora = false;
                    this.clear();
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Formatea el número de identificación tributaria (NIT) de la aseguradora ingresado en el formulario de crear aseguradora
         * Si el número de dígitos es menor o igual a 9, se formatea con puntos solamente
         * Si el número de dígitos es mayor a 9, se formatea con puntos y guión 
         * Se usan las expresiones regulares para dar el formato deseado
         */
        formatoNIT() {
            let nitFormulario = this.crearAseguradora.nit.replace(/\D/g, '');
            const diezDigitos = nitFormulario.length === 10;
            this.crearAseguradora.nit = this.formatearNit(nitFormulario);
            if (diezDigitos) {
                this.listarCodigo();
            } else {
                this.listaCodigos = [];
                this.validarNit = true;
            }
        },
        /** Lista los códigos de detalle clientes que están relacionados con el NIT junto con el dígito de verificación */
        listarCodigo() {
            this.listaCodigos = [];
            const nit = this.crearAseguradora.nit.replace(/\D/g, '');
            const nueveDigitos = nit.substring(0, 9);
            const digitoVerificacion = nit.substring(9, 10);
            this.$http
                .get(`msa-administration/caDecli/listarCodigosClientes`, {
                    params: {
                        nit: nueveDigitos,
                        digVeri: digitoVerificacion,
                        codEmpr: this.enterprise.code
                    },
                }).then((response) => {
                    const resultados = response.data;
                    // si no hay resultados se deja el campo del código detalle cliente vacío
                    if (resultados.length === 0) {
                        // se activa el campo para editar
                        this.disabled = false;
                        this.crearAseguradora.detalleCliente = '';
                    } else {
                        // se visualiza el código detalle cliente con su respectivo nombre separados por un guión,
                        // tanto en el menú de selección como cuando selecciona una opción y se guarda en el campo de entrada.
                        resultados.forEach((item) => {
                            this.listaCodigos.push({
                                text: `${item.codDecl} - ${item.nomDecl}`,
                                value: item.codDecl
                            });
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Abre el diálogo de editar aseguradora con los datos del objeto seleccionado(datos actuales de la aseguradora) 
         * le agrego un formato al nit, para que se visualice con puntos y guiones, si son 9 digitos le agrega solo puntos
         * si son mas, le agrega un guión después del noveno dígito
         * @param {*} item - Objeto que contiene los datos de la aseguradora a editar.
         */
        abrirDialogoEditar(item) {
            this.crearAseguradora = { ...item };
            this.crearAseguradora.nit = item.nit + item.digitoVerificacion;
            this.editar = true;
            this.formatoNIT();
            // si el nit posee tiene los 10 dígitos y ademas el digito de verificacion coincide con el nit y lista los códigos detalle cliente
            // el campo del nit se desactiva para no editar , ya que no es necesario porque está bien diligenciado.
            const nitFormulario = this.crearAseguradora.nit.replace(/\D/g, '');
            if (nitFormulario.length === 10) {
                this.disabled = true;
                this.listarCodigo();
            }
            this.aseguradoraSeleccionada = item;
            this.tituloDialogo = 'Editar aseguradora';
            this.dialogoAseguradora = true;
            this.validarNit = true;
            // si algún campo no cumple con las condiciones se activa la validación apenas se abra el formulario
            this.$nextTick(() => {
                if (this.validarNit) {
                    this.$refs.nitValidationProvider.validate();
                    this.$refs.nombreValidation.validate();
                    this.$refs.codigoValidation.validate();
                } else {
                    this.validarNit = false;
                }
            });
        },
        /**
         * Actualiza la información de la aseguradora seleccionada mediante una petición put, se puede actualizar el NIT, Nombre y/o código detalle cliente
         * al nit al guardar en base de datos le quito el formato, es decir, le quito los puntos y guiones, finalmente se actualiza la tabla de datos y cierra el diálogo 
         * Se agrega el método substring para separar el nit del dígito de verificación ya que ambos se ingresan en el mismo campo de entrada.
         */
        editarAseguradora() {
            const nit = this.crearAseguradora.nit.replace(/\D/g, '');
            const nueveDigitos = nit.substring(0, 9);
            const digitoVerificacion = nit.substring(9, 10);
            this.$http
                .put(`msa-administration/aseguradora/actualizar`, {
                    idAseguradora: this.aseguradoraSeleccionada.idAseguradora,
                    nit: nueveDigitos,
                    digitoVerificacion: digitoVerificacion,
                    nombreAseguradora: this.crearAseguradora.nombreAseguradora,
                    detalleCliente: this.crearAseguradora.detalleCliente,
                    eliminado: false,
                    empresa: {
                        idEmpresa: this.aseguradoraSeleccionada.empresa.idEmpresa
                    },
                    lastmodifiedby: this.lastmodifiedby
                }).then(() => {
                    this.listaAseguradora();
                    this.dialogoAseguradora = false;
                    setTimeout(() => {
                        this.clear();
                    }, 1000);
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Abre un diálogo para cambiar el estado de una aseguradora seleccionada
         * @param {Object} item - El objeto que representa la aseguradora seleccionada(datos actuales de la aseguradora)
         */
        abrirDialogoEstado(item) {
            this.aseguradoraSeleccionada = item;
            if (this.aseguradoraSeleccionada.digitoVerificacion !== null) {
                this.dialogoCambiarEstado = true
                this.mensajeEstado = this.aseguradoraSeleccionada.eliminado === false ? '¿Desea inactivar la aseguradora?' : '¿Desea activar la aseguradora?';
            } else {
                this.errorEstado = true;
                setTimeout(() => {
                    this.errorEstado = false;
                }, 2500)
            }
        },
        /**
         * Cambia el estado de una aseguradora y actualiza su estado en la base de datos 
         * mediante una petición put,finalmente actualiza la tabla y cierra el diálogo
         */
        cambiarEstado() {
            this.cargando = true;
            this.aseguradoraSeleccionada.eliminado = (this.aseguradoraSeleccionada.eliminado == false) ? true : false;
            const cambiarEstado = {
                idAseguradora: this.aseguradoraSeleccionada.idAseguradora,
                eliminado: this.aseguradoraSeleccionada.eliminado,
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
                .put(`msa-administration/aseguradora/actualizarEstado`, cambiarEstado)
                .then(() => {
                    this.listaAseguradora();
                    this.dialogoCambiarEstado = false;
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Muestra tabla de trazabilidad de una aseguradora determinada mediante una petición get que obtiene los datos de la trazabilidad
         * a través del parámetro id que es la variable "aseguradoraSeleccionada.id"
         * @param {Object} item - El objeto de la aseguradora seleccionada(los datos datos actuales de la aseguradora) para el cual se mostrará 
         * la trazabilidad.
         */
        verTablaTrazabilidad(item) {
            this.dialogoTrazabilidad = true;
            this.aseguradoraSeleccionada = item;
            this.$http
                .get(`msa-administration/aseguradora/listarTraza`, {
                    params: {
                        id: this.aseguradoraSeleccionada.idAseguradora,
                    }
                }).then(response => {
                    this.tablaTrazabilidad.items = response.data;
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Valida si el campo de un objeto debe ser excluido en la tabla de trazabilidad
         * @param {Object} item - El objeto que contiene el campo a validar.
         * @param {string} item.campo - El nombre del campo a validar.
         */
        validarCampos(item) {
            const excluir = ['nombre_aseguradora', 'detalle_cliente']
            return excluir.includes(item.campo)
        },
        /**
         * Formatea una cadena de fecha y hora en un formato "YYYY/MM/DD HH:MM a.m./p.m."
         * @param {string} dateTimeString - La cadena de fecha y hora en formato ISO 8601.
         */
        formatoFecha(dateTimeString) {
            const fecha = new Date(dateTimeString);
            const horas = fecha.getHours();
            const minutos = fecha.getMinutes();
            const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
            const formatoHoras = horas % 12 || 12;
            const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
            const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const formatoDia = fecha.getDate().toString().padStart(2, '0');
            const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos} ${ampm}`;
            return formatoFechaHora;
        },
        /**
         * Método que restablece varias variables y propiedades a sus valores predeterminados o vacíos, también restablece los campos de entrada de un 
         * formulario a su estado inicial.
         * Se utiliza para limpiar y preparar el estado del programa antes de realizar una nueva operación.
         */
        clear() {
            this.validarNit = false;
            this.editar = false;
            this.disabled = false;
            this.botonDisabled = false;
            this.dialogoAseguradora = false;
            this.crearAseguradora.nit = '';
            this.crearAseguradora.nombreAseguradora = '';
            this.crearAseguradora.detalleCliente = [];
            this.$refs.observer.reset();
            this.tituloDialogo = 'Crear aseguradora';
            this.listaCodigos = [];
        },
    },
    mounted() {
        window.addEventListener('beforeunload', function () {
            localStorage.removeItem('sortfieldState');
        });
        this.listaAseguradora();
        this.lastmodifiedby = this.auth.username.toUpperCase();
    }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    width: 100%;
    height: 86vh;
}

.filtro {
    width: 25%;
}

.encabezado {
    background-color: #1867c0;
    color: white;
}

.dialogo {
    padding-top: 20px !important;
}

.campo {
    width: 100% !important;
}

.tooltip {
    height: 2.5rem;
    margin-left: 3px;
}

.select {
    width: 374px !important;
}

::v-deep .elevation .v-data-footer {
    width: 100%;
}

::v-deep .traza .v-data-footer__select {
    display: none;
}

::v-deep .traza .v-data-footer {
    padding-top: 10px;
    justify-content: end;
}

::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}

.rotate-animation {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}

.alerta {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff5252;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 25vw;
}
</style>